import * as i0 from '@angular/core';
import { Injectable, NgModule, InjectionToken } from '@angular/core';
import * as i1$1 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i1 from '@spartacus/core';
import { CxEvent, facadeFactory, provideDefaultConfigFactory, provideDefaultConfig, Config } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent, PageEvent } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultCartConfig = {
  cart: {
    validation: {
      enabled: false
    },
    selectiveCart: {
      enabled: false
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const defaultCartRoutingConfig = {
  routing: {
    routes: {
      cart: {
        paths: ['cart']
      }
    }
  }
};

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ORDER_ENTRIES_CONTEXT = Symbol('ORDER_ENTRIES_CONTEXT');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Base cart event. Most cart events should have these properties.
 */
class CartEvent extends CxEvent {}
class CreateCartEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CreateCartEvent';
  }
}
class CreateCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CreateCartSuccessEvent';
  }
}
class CreateCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CreateCartFailEvent';
  }
}
class CartAddEntryEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartAddEntryEvent';
  }
}
class CartAddEntrySuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartAddEntrySuccessEvent';
  }
}
class CartAddEntryFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartAddEntryFailEvent';
  }
}
class CartRemoveEntryFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartRemoveEntryFailEvent';
  }
}
class CartRemoveEntrySuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartRemoveEntrySuccessEvent';
  }
}
class CartUpdateEntrySuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartUpdateEntrySuccessEvent';
  }
}
class CartUpdateEntryFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartUpdateEntryFailEvent';
  }
}
class CartUiEventAddToCart extends CxEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'CartUiEventAddToCart';
  }
}
/**
 * Fired when the cart has been successfully merged.
 */
class MergeCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'MergeCartSuccessEvent';
  }
}
/**
 * Triggers the loading of the cart.
 */
class LoadCartEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'LoadCartEvent';
  }
}
/** Removes the cart. */
class RemoveCartEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RemoveCartEvent';
  }
}
/**
 * Fired when the cart has been deleted.
 */
class DeleteCartEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'DeleteCartEvent';
  }
}
class DeleteCartSuccessEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'DeleteCartSuccessEvent';
  }
}
class DeleteCartFailEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'DeleteCartFailEvent';
  }
}
class AddCartVoucherEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'AddCartVoucherEvent';
  }
}
class AddCartVoucherSuccessEvent extends AddCartVoucherEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'AddCartVoucherSuccessEvent';
  }
}
class AddCartVoucherFailEvent extends AddCartVoucherEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'AddCartVoucherFailEvent';
  }
}
class RemoveCartVoucherEvent extends CartEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RemoveCartVoucherEvent';
  }
}
class RemoveCartVoucherSuccessEvent extends RemoveCartVoucherEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RemoveCartVoucherSuccessEvent';
  }
}
class RemoveCartVoucherFailEvent extends RemoveCartVoucherEvent {
  /**
   * Event's type
   */
  static {
    this.type = 'RemoveCartVoucherFailEvent';
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_BASE_FEATURE = 'cartBase';
const CART_BASE_CORE_FEATURE = 'cartBaseCore';
const MINI_CART_FEATURE = 'miniCart';
const ADD_TO_CART_FEATURE = 'addToCart';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class MultiCartFacade {
  static {
    this.ɵfac = function MultiCartFacade_Factory(t) {
      return new (t || MultiCartFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MultiCartFacade,
      factory: () => (() => facadeFactory({
        facade: MultiCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getCart', 'getCarts', 'getCartEntity', 'isStable', 'createCart', 'mergeToCurrentCart', 'loadCart', 'getEntries', 'getLastEntry', 'addEntry', 'addEntries', 'removeEntry', 'updateEntry', 'getEntry', 'assignEmail', 'removeCart', 'deleteCart', 'reloadCart', 'getCartIdByType'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiCartFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: MultiCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getCart', 'getCarts', 'getCartEntity', 'isStable', 'createCart', 'mergeToCurrentCart', 'loadCart', 'getEntries', 'getLastEntry', 'addEntry', 'addEntries', 'removeEntry', 'updateEntry', 'getEntry', 'assignEmail', 'removeCart', 'deleteCart', 'reloadCart', 'getCartIdByType'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class MultiCartEventListener {
  constructor(eventService, multiCartFacade) {
    this.eventService = eventService;
    this.multiCartFacade = multiCartFacade;
    this.subscriptions = new Subscription();
    this.onCartBaseAction();
  }
  /**
   * Registers events for the cart base actions.
   */
  onCartBaseAction() {
    this.subscriptions.add(this.eventService.get(LoadCartEvent).subscribe(({
      userId,
      cartId
    }) => {
      if (userId && cartId) {
        this.multiCartFacade.loadCart({
          userId,
          cartId
        });
      }
    }));
    this.subscriptions.add(this.eventService.get(RemoveCartEvent).subscribe(({
      cartId
    }) => {
      this.multiCartFacade.removeCart(cartId);
    }));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  static {
    this.ɵfac = function MultiCartEventListener_Factory(t) {
      return new (t || MultiCartEventListener)(i0.ɵɵinject(i1.EventService), i0.ɵɵinject(MultiCartFacade));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MultiCartEventListener,
      factory: MultiCartEventListener.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MultiCartEventListener, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.EventService
  }, {
    type: MultiCartFacade
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CartBaseEventModule {
  constructor(_multiCartEventListener) {
    // Intentional empty constructor
  }
  static {
    this.ɵfac = function CartBaseEventModule_Factory(t) {
      return new (t || CartBaseEventModule)(i0.ɵɵinject(MultiCartEventListener));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CartBaseEventModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartBaseEventModule, [{
    type: NgModule,
    args: [{}]
  }], () => [{
    type: MultiCartEventListener
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const ActiveCartOrderEntriesContextToken = new InjectionToken('ActiveCartOrderEntriesContext');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function defaultCartComponentsConfig() {
  const config = {
    featureModules: {
      [CART_BASE_FEATURE]: {
        cmsComponents: ['CartApplyCouponComponent', 'CartComponent', 'CartProceedToCheckoutComponent', 'CartTotalsComponent', 'SaveForLaterComponent', 'ClearCartComponent']
      },
      [MINI_CART_FEATURE]: {
        cmsComponents: ['MiniCartComponent']
      },
      [ADD_TO_CART_FEATURE]: {
        cmsComponents: ['ProductAddToCartComponent']
      },
      // By default core is bundled together with components.
      // The cart lib should keep using this default.
      //
      // While the lazy loading configurations make it possible to
      // split the core part and the components part, it is required that
      // they stay together for the cart lib.  This compromise is required to
      // optimize performances by delaying the moment the cart lib is loaded and
      // making sure cart lib is loaded when needed.
      [CART_BASE_CORE_FEATURE]: CART_BASE_FEATURE
    }
  };
  return config;
}
class CartBaseRootModule {
  static {
    this.ɵfac = function CartBaseRootModule_Factory(t) {
      return new (t || CartBaseRootModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CartBaseRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfigFactory(defaultCartComponentsConfig), provideDefaultConfig(defaultCartConfig), provideDefaultConfig(defaultCartRoutingConfig)],
      imports: [CartBaseEventModule, RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'cart',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: ActiveCartOrderEntriesContextToken
          }
        }
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartBaseRootModule, [{
    type: NgModule,
    args: [{
      imports: [CartBaseEventModule, RouterModule.forChild([{
        // @ts-ignore
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: {
          cxRoute: 'cart',
          cxContext: {
            [ORDER_ENTRIES_CONTEXT]: ActiveCartOrderEntriesContextToken
          }
        }
      }])],
      providers: [provideDefaultConfigFactory(defaultCartComponentsConfig), provideDefaultConfig(defaultCartConfig), provideDefaultConfig(defaultCartRoutingConfig)]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CartConfig {
  static {
    this.ɵfac = function CartConfig_Factory(t) {
      return new (t || CartConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CartConfig,
      factory: function CartConfig_Factory(t) {
        let r = null;
        if (t) {
          r = new (t || CartConfig)();
        } else {
          r = i0.ɵɵinject(Config);
        }
        return r;
      },
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useExisting: Config
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Indicates that a user visited a cart page.
 */
class CartPageEvent extends PageEvent {
  /** event's type */
  static {
    this.type = 'CartPageEvent';
  }
}

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ActiveCartFacade {
  static {
    this.ɵfac = function ActiveCartFacade_Factory(t) {
      return new (t || ActiveCartFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ActiveCartFacade,
      factory: () => (() => facadeFactory({
        facade: ActiveCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getActive', 'takeActive', 'getActiveCartId', 'takeActiveCartId', 'getEntries', 'getLastEntry', 'getLoading', 'isStable', 'addEntry', 'removeEntry', 'updateEntry', 'getEntry', 'addEmail', 'getAssignedUser', 'isGuestCart', 'addEntries', 'requireLoadedCart', 'reloadActiveCart', 'hasPickupItems', 'hasDeliveryItems', 'getPickupEntries', 'getDeliveryEntries'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActiveCartFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: ActiveCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getActive', 'takeActive', 'getActiveCartId', 'takeActiveCartId', 'getEntries', 'getLastEntry', 'getLoading', 'isStable', 'addEntry', 'removeEntry', 'updateEntry', 'getEntry', 'addEmail', 'getAssignedUser', 'isGuestCart', 'addEntries', 'requireLoadedCart', 'reloadActiveCart', 'hasPickupItems', 'hasDeliveryItems', 'getPickupEntries', 'getDeliveryEntries'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CartValidationFacade {
  static {
    this.ɵfac = function CartValidationFacade_Factory(t) {
      return new (t || CartValidationFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CartValidationFacade,
      factory: () => (() => facadeFactory({
        facade: CartValidationFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['validateCart', 'getValidationResults']
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartValidationFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: CartValidationFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['validateCart', 'getValidationResults']
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CartVoucherFacade {
  static {
    this.ɵfac = function CartVoucherFacade_Factory(t) {
      return new (t || CartVoucherFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CartVoucherFacade,
      factory: () => (() => facadeFactory({
        facade: CartVoucherFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['addVoucher', 'removeVoucher', 'getAddVoucherResultError', 'getAddVoucherResultSuccess', 'getAddVoucherResultLoading', 'resetAddVoucherProcessingState'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartVoucherFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: CartVoucherFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['addVoucher', 'removeVoucher', 'getAddVoucherResultError', 'getAddVoucherResultSuccess', 'getAddVoucherResultLoading', 'resetAddVoucherProcessingState'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class SelectiveCartFacade {
  static {
    this.ɵfac = function SelectiveCartFacade_Factory(t) {
      return new (t || SelectiveCartFacade)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SelectiveCartFacade,
      factory: () => (() => facadeFactory({
        facade: SelectiveCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getCart', 'getEntries', 'isStable', 'addEntry', 'removeEntry', 'updateEntry', 'getEntry'],
        async: true
      }))(),
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectiveCartFacade, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: () => facadeFactory({
        facade: SelectiveCartFacade,
        feature: CART_BASE_CORE_FEATURE,
        methods: ['getCart', 'getEntries', 'isStable', 'addEntry', 'removeEntry', 'updateEntry', 'getEntry'],
        async: true
      })
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Context for `CartItemComponent`.
 */
class CartItemContext {
  static {
    this.ɵfac = function CartItemContext_Factory(t) {
      return new (t || CartItemContext)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CartItemContext,
      factory: CartItemContext.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartItemContext, [{
    type: Injectable
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Identifiers of outlets inside the cart details view.
 */
var CartOutlets;
(function (CartOutlets) {
  CartOutlets["ITEM"] = "cx-cart-item";
  CartOutlets["LIST_ITEM"] = "cx-cart-item-list-row";
  CartOutlets["ITEM_DETAILS"] = "cx-cart-item.details";
  CartOutlets["ITEM_CONFIGURATOR_ISSUES"] = "cx-configurator-issues-notification";
  CartOutlets["ITEM_BUNDLE_DETAILS"] = "cx-cart-item.bundle-details";
  CartOutlets["ITEM_DELIVERY_DETAILS"] = "cx-cart-item.delivery-details";
  CartOutlets["ORDER_SUMMARY"] = "cx-order-summary";
  CartOutlets["CART_ITEM_LIST"] = "cx-cart-item-list";
  CartOutlets["ADD_TO_CART_CONTAINER"] = "cx-add-to-cart-container";
  CartOutlets["PICKUP_INFO"] = "cx-pickup-info";
  CartOutlets["ADD_TO_CART_PICKUP_OPTION"] = "cx-add-to-cart-pickup-option";
  CartOutlets["DELIVERY_MODE"] = "cx-delivery-mode";
  CartOutlets["ORDER_OVERVIEW"] = "cx-order-overview";
  CartOutlets["CPQ_QUOTE_MODULE"] = "cx-cpq-quote";
  CartOutlets["CPQ_QUOTE_HEADING"] = "cx-cpq-quote-heading";
  CartOutlets["CPQ_QUOTE"] = "cx-cpq-quote-offer";
})(CartOutlets || (CartOutlets = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var PromotionLocation;
(function (PromotionLocation) {
  PromotionLocation["ActiveCart"] = "CART";
  PromotionLocation["Checkout"] = "CHECKOUT";
  PromotionLocation["Order"] = "ORDER";
  PromotionLocation["SaveForLater"] = "SAVE_FOR_LATER";
  PromotionLocation["SavedCart"] = "SAVED_CART";
})(PromotionLocation || (PromotionLocation = {}));
/**
 * The possible types of 'abstract orders'.
 */
var AbstractOrderType;
(function (AbstractOrderType) {
  /**
   * Active cart
   */
  AbstractOrderType["CART"] = "Cart";
  AbstractOrderType["ORDER"] = "Order";
  AbstractOrderType["QUOTE"] = "Quote";
  AbstractOrderType["SAVED_CART"] = "SavedCart";
})(AbstractOrderType || (AbstractOrderType = {}));
var CartType;
(function (CartType) {
  CartType["ACTIVE"] = "Active";
  CartType["WISH_LIST"] = "WishList";
  CartType["SELECTIVE"] = "Selective";
  CartType["NEW_CREATED"] = "NewCreated";
})(CartType || (CartType = {}));
var CartValidationStatusCode;
(function (CartValidationStatusCode) {
  CartValidationStatusCode["NO_STOCK"] = "noStock";
  CartValidationStatusCode["LOW_STOCK"] = "lowStock";
  CartValidationStatusCode["REVIEW_CONFIGURATION"] = "reviewConfiguration";
  CartValidationStatusCode["PRICING_ERROR"] = "pricingError";
  CartValidationStatusCode["UNRESOLVABLE_ISSUES"] = "unresolvableIssues";
})(CartValidationStatusCode || (CartValidationStatusCode = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var OrderEntriesSource;
(function (OrderEntriesSource) {
  OrderEntriesSource["ACTIVE_CART"] = "ACTIVE_CART";
  OrderEntriesSource["NEW_SAVED_CART"] = "NEW_SAVED_CART";
  OrderEntriesSource["QUICK_ORDER"] = "QUICK_ORDER";
  OrderEntriesSource["SAVED_CART"] = "SAVED_CART";
  OrderEntriesSource["ORDER_CONFIRMATION"] = "ORDER_CONFIRMATION";
  OrderEntriesSource["ORDER_DETAILS"] = "ORDER_DETAILS";
  OrderEntriesSource["UNIT_ORDER_DETAILS"] = "UNIT_ORDER_DETAILS";
})(OrderEntriesSource || (OrderEntriesSource = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
var ProductImportStatus;
(function (ProductImportStatus) {
  ProductImportStatus["SUCCESS"] = "success";
  ProductImportStatus["LOW_STOCK"] = "lowStock";
  ProductImportStatus["NO_STOCK"] = "noStock";
  ProductImportStatus["UNKNOWN_IDENTIFIER"] = "unknownIdentifier";
  ProductImportStatus["UNKNOWN_ERROR"] = "unknownError";
  ProductImportStatus["LIMIT_EXCEEDED"] = "limitExceeded";
})(ProductImportStatus || (ProductImportStatus = {}));

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
const CART_NORMALIZER = new InjectionToken('CartNormalizer');
const ORDER_ENTRY_PROMOTIONS_NORMALIZER = new InjectionToken('OrderEntryPromotionsNormalizer');
const CART_MODIFICATION_NORMALIZER = new InjectionToken('CartModificationNormalizer');
const SAVE_CART_NORMALIZER = new InjectionToken('SaveCartNormalizer');
const CART_VOUCHER_NORMALIZER = new InjectionToken('CartVoucherNormalizer');

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/** AUGMENTABLE_TYPES_END */

/**
 * Generated bundle index. Do not edit.
 */

export { ADD_TO_CART_FEATURE, AbstractOrderType, ActiveCartFacade, ActiveCartOrderEntriesContextToken, AddCartVoucherEvent, AddCartVoucherFailEvent, AddCartVoucherSuccessEvent, CART_BASE_CORE_FEATURE, CART_BASE_FEATURE, CART_MODIFICATION_NORMALIZER, CART_NORMALIZER, CART_VOUCHER_NORMALIZER, CartAddEntryEvent, CartAddEntryFailEvent, CartAddEntrySuccessEvent, CartBaseEventModule, CartBaseRootModule, CartConfig, CartEvent, CartItemContext, CartOutlets, CartPageEvent, CartRemoveEntryFailEvent, CartRemoveEntrySuccessEvent, CartType, CartUiEventAddToCart, CartUpdateEntryFailEvent, CartUpdateEntrySuccessEvent, CartValidationFacade, CartValidationStatusCode, CartVoucherFacade, CreateCartEvent, CreateCartFailEvent, CreateCartSuccessEvent, DeleteCartEvent, DeleteCartFailEvent, DeleteCartSuccessEvent, LoadCartEvent, MINI_CART_FEATURE, MergeCartSuccessEvent, MultiCartEventListener, MultiCartFacade, ORDER_ENTRIES_CONTEXT, ORDER_ENTRY_PROMOTIONS_NORMALIZER, OrderEntriesSource, ProductImportStatus, PromotionLocation, RemoveCartEvent, RemoveCartVoucherEvent, RemoveCartVoucherFailEvent, RemoveCartVoucherSuccessEvent, SAVE_CART_NORMALIZER, SelectiveCartFacade, defaultCartComponentsConfig };
